<template>
  <div id="app">
    <BsAlert></BsAlert>
    <div :class='$store.state.isMobi?"":"float"' style="background-color: #000;">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-dark" style="background-color: #000;">
          <router-link class="navbar-brand" to="/">
            <div class="row justify-content-center align-items-center g-2">
              <div class="col">
                <img src="./assets/df-logo.png" width="50" />
              </div>
              <div class="col">
                <span class="text-20 d-block">都富跟单社区</span>
                <span class="text-12 d-block">DF COPYTRADE COMMUNITY</span>
              </div>
            </div>
          </router-link>
          <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="collapsibleNavId">
            <ul class="navbar-nav me-auto mt-2 mt-lg-0">
              <li class="nav-item" v-for="n in navs">
                <router-link class="nav-link" :to="n.url">
                  <i :class="n.icon">&nbsp;</i>
                  {{ n.title }}
                </router-link>
              </li>              
            </ul>
          </div>
          <div class="text-center flex-grow-1 flex-lg-grow-0">
            <login-info login-url="/web/login" login-out-url="/web/logout" reg-url="/web/register" home-url="/web"></login-info>
          </div>
        </nav>
      </div>
    </div>
    <router-view />
    <section class="footer text-999" style="background-color: #000;">
      <div class="container p-4 pt-5">
        <div class="row justify-content-center align-items-start pb-md-0">
          <div class="col-lg-3 mb-4">
            <p class="text-28 text-white">交易达人</p>
            <div class="mb-3">
              <input type="text" class="form-control" maxlength="26" aria-describedby="helpId" placeholder="输入手机/邮箱">
            </div>
            <router-link class="btn btn-danger" to="/web/register">立即注册</router-link>
          </div>
          <div class="col-lg-3 mb-4">
            <p class="text-28 text-white">快捷链接</p>
            <div class="row">
              <p class="col-6 col-md-12" v-for="n in sfooter_links">
                <router-link class="nav-link" :to="n.url">{{ n.title }}</router-link>
              </p>
            </div>
          </div>
          <div class="col-lg-3 mb-4">
            <p class="text-28 text-white">服务支持</p>
            <div class="row">
              <p class="col-6 col-md-12" v-for="n in sfooter_supports">
                <router-link class="nav-link" :to="n.url">{{ n.title }}</router-link>
              </p>
            </div>
          </div>
          <div class="col-lg-3 mb-4">
            <p class="text-28 text-white">联系</p>
            <p>Melbourne, Australia The Empire State Building is located at 398 Elizabeth Street, Melbourne's CBD</p>
            <p>wechat : MT4API</p>
          </div>
        </div>
        <div class="pt-md-4 mb-4">
          <p>
            Copyright © 2020-2023 All Rights By
            <span class="text-red">DOFOLLOWFX</span>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BsAlert from '@/components/BsAlert.vue'
import LoginInfo from '@/components/LoginInfo.vue'
export default {
  components: {
    BsAlert,
    LoginInfo
  },
  data(){
    return{
      navs:[
        {url:'/home',title:'首页',icon:'fa fa-home'},
        {url:'/stars',title:'信号方',icon:'fa fa-shield'},
        //{url:'/article',title:'动态',icon:'fa fa-globe'},        
        {url:'/plats',title:'经纪商',icon:'fa fa-street-view'},
        {url:'/analy',title:'分析工具',icon:'fa fa-wpexplorer'},
        {url:'/about/help',title:'帮助中心',icon:'fa fa-globe'},
        {url:'/about/us',title:'关于我们',icon:'fa fa-globe'}
      ],
      sfooter_links:[
        {url:'/web/login',title:'登录'},
        {url:'/web/register',title:'注册'},
        {url:'/stars',title:'绑定账户'},
        {url:'/plats',title:'支持的经纪商'}
      ],
      sfooter_supports:[
        {url:'/about/us',title:'关于我们'},
        {url:'/about/help',title:'帮助中心'},
        {url:'/about/us',title:'用户协议'},
        {url:'/about/partner',title:'客服'}
      ]
    }
  },
  methods:{
    
  }
}
</script>
<style>
#app {
  background-color: #f4f7fe;
}
.float{
  position: sticky;top: 0;z-index: 100;width: 100%;
}
nav .nav-item{
  color: #fff;
  padding: 0 10px;
}
nav .nav-link{
 padding: 8px 10px!important;
}
nav .navbar-brand{
  background-color: inherit!important;
}
nav .nav-link:focus, nav a.router-link-exact-active, nav a.router-link-active{
  background-color: #e74242;
  color: #fff;
}
.user-head-img{
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  border-radius: 50%;
}
.footer{
  margin-top: 20px;
}
img{max-width: 100%;}
.btn-200{min-width: 200px;}
.btn-cursor{cursor: pointer;}
.text-indent{text-indent: 2em;}
.text-black{color: #000;}
.text-red{color: #e74242;}
.text-333{color: #333;}
.text-666{color: #666;}
.text-999{color: #999;}
.text-12{font-size: 12px;line-height: 20px;}
.text-14{font-size: 14px;line-height: 22px;}
.text-16{font-size: 16px;line-height: 24px;}
.text-18{font-size: 18px;line-height: 26px;}
.text-20{font-size: 20px;line-height: 28px;}
.text-22{font-size: 22px;line-height: 30px;}
.text-24{font-size: 24px;line-height: 32px;}
.text-26{font-size: 26px;line-height: 34px;}
.text-28{font-size: 28px;line-height: 36px;}
.text-30{font-size: 30px;line-height: 38px;}
.text-36{font-size: 36px;line-height: 44px;}
.text-40{font-size: 40px;line-height: 48px;}
.text-46{font-size: 46px;line-height: 54px;}
.text-60{font-size: 60px;line-height: 68px;}
</style>
