<template>
    <div class="logininfo">
        <div v-if="loginUserName">
            <div v-if="loginUserName != '-'">
                <!--<a target="_self" :href="homeUrl" class="link-warning text-decoration-none">
                    <i class="fa fa-user">&nbsp;{{ loginUserName }}</i>
                </a>-->
                <ul class="navbar-nav me-auto mt-2 mt-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link link-warning dropdown-toggle" href="#" id="dropdownId" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-user">&nbsp;{{ loginUserName }}</i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownId">
                            <a class="dropdown-item" :href="homeUrl">进入后台</a>
                            <a class="dropdown-item" :href="loginOutUrl">退出登录</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <a target="_blank" :href="loginUrl" class="btn btn-outline-danger">登录</a>
                <a target="_blank" :href="regUrl" class="btn btn-danger">注册</a>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'LoginInfo',
    props: {
        loginUrl: String,
        loginOutUrl: String,
        regUrl: String,
        homeUrl: String
    },
    data() {
        return {
            loginUserName: ''
        }
    },
    mounted() {
        this.$http.getLoginUser().then(res => {
            if (res.success) {
                this.loginUserName = res.data.name
            } else {
                this.loginUserName = '-'
            }
        })
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
    width: 40%;
    min-width: 100px;
    margin: 0 5px;
}
</style>
  