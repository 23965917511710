import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobi: false,
    bsAlert:{
      dismissCountDown:0,
      type:'',
      msg:''
    }
  },
  getters: {
  },
  mutations: {
    setIsMobi(state, b){
      state.isMobi = b
    },
    showAlert(state,bas){
      state.bsAlert.dismissCountDown = 3
      state.bsAlert.msg = bas.msg
      state.bsAlert.type = bas.type
    }
  },
  actions: {
    showAlert(content,bsa){
      bsa.type = bsa.type||'warning'
      content.commit('showAlert',bsa)
    }
  },
  modules: {
  }
})
