<template>
  <div class="bsalert">
    <div v-show="bsAlert.dismissCountDown" :class='"alert alert-" + bsAlert.type'>
      <span>{{ bsAlert.msg }}</span>
      <button type="button" class="btn-close" @click="countDownChanged"></button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
let bs_Close = 0
export default {
  name: 'BsAlert',
  computed: {
    ...mapState(["bsAlert"])
  },
  updated() {
    if (bs_Close != 0) {
      clearTimeout(bs_Close)
    }
    bs_Close = setTimeout(() => {
      this.$store.state.bsAlert.dismissCountDown = 0
    }, 3000);
  },
  methods: {
    countDownChanged() {
      this.$store.state.bsAlert.dismissCountDown = 0
    }
  }
}
</script>

<style scoped> 
.bsalert {
   position: fixed;
   text-align: center;
   width: 100%;
   margin-top: 15px;
   z-index: 999;
 }

 .bsalert .alert {
   max-width: 600px;
   margin: 0 auto;
   text-align: left;
 }

 .bsalert .btn-close {
   float: right;
 }
</style>