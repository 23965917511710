import App from "@/main"

window.onload = window.onresize = function(){
    App.$store.commit('setIsMobi', document.body.clientWidth < 768)
}

export default{
    showAlert(msg,type){
        App.$store.dispatch('showAlert',{msg,type})
    },
    go(url){
        App.$router.push(url)
    },
    goLogin(){
        window.open("/web/login")
    },
    getCookie(name){
        console.log(document.cookie);
        var reg = new RegExp("(^|;)" + name + "=([^;]*)(;|$)");
        var r = document.cookie.match(reg);
        if (r != null) return decodeURI(r[2]);
        return '';
    },
    setCookie(name,value){
        document.cookie = name + "=" + value+";path=/;";
    }
}