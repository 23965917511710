import 'bootstrap/dist/css/bootstrap.css'
import 'vue-slider-component/theme/antd.css'
import './assets/font-awesome.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
import bootstrap from 'bootstrap/dist/js/bootstrap'
import api from './utils/api'
import com from './utils/com'


Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(bootstrap)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)

import VueSlider from 'vue-slider-component';
Vue.component('VueSlider', VueSlider)

Vue.prototype.$http = api
Vue.prototype.$com = com
Vue.prototype.$cookie = VueCookie

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')